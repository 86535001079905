.accent {
	color: var(--accent-color);
}

.name {
	margin-bottom: 10px;
	margin-top: 10px;
	font-weight: 800;
	font-size: 65px;
	line-height: 72px;
}

#home a {
	margin-top: 20px;
}

@media (max-width: 500px) {
	.name {
		font-size: 36px;
		line-height: 52px;
	}

	#home h1 {
		font-size: 21px;
	}
	#home .mono {
		font-size: 17px;
	}
}

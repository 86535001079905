.experience-container {
	display: flex;
	flex-direction: column;
	width: 700px;
}

.experience {
	display: flex;
	flex-direction: column;
	background-color: var(--light-bg-color);
	border-radius: 5px;
	padding: 30px;
	margin: 10px 0;
}

.experience-title {
	font-weight: 500;
	font-size: 26px;
}

.experience-company {
	color: var(--accent-color);
	font-size: 24px;
	font-weight: 500;
	overflow-wrap: break-word;
}

.experience-location {
	font-style: italic;
	font-size: 18px;
}

.experience-date {
	font-style: italic;
	font-size: 18px;
}

.experience-description {
	margin-top: 20px;
	color: var(--white-faded);
	font-size: 16px;
}

.experience-description div::before {
	content: "\2022";
	color: var(--accent-color);
	font-size: 18px;
	font-weight: bold;
	display: inline-block;
	width: 1em;
}

@media (max-width: 900px) {
	.experience-container {
		width: 100%;
	}
}

.skills-container {
	display: flex;
	flex-wrap: wrap;
	max-width: 640px;
	gap: 15px;
}
.skill:last-child {
	width: 275px;
}
.skill {
	background-color: var(--light-bg-color);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 10px;
	width: 130px;
	height: 130px;
	border-radius: 5px;
}

.skill img {
	width: 70px;
	height: 70px;
}

@media (max-width: 500px) {
	.skills-container {
		gap: 10px;
	}
	.skill:last-child {
		width: 210px;
	}
	.skill {
		width: 100px;
		height: 100px;
		font-size: 15px;
	}
	.skill img {
		width: 50px;
		height: 50px;
	}
}

.contacts-container {
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;
	background-color: var(--light-bg-color);
	padding: 50px;
	border-radius: 5px;
}
.contact {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 15px;
}

.contact a {
	text-decoration: none;
	color: white;
}

.contact a:hover {
	text-decoration: underline;
	color: var(--accent-color);
}

.iframe-container {
	position: relative;
	overflow: hidden;
	width: 100%;
	margin-top: 20px;
	padding-top: 140%;
}
.responsive-iframe {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	border-radius: 5px;
	border: solid 2px var(--bg-color);
}

@media (max-width: 550px) {
	.contacts-container {
		padding: 20px;
	}
	.contact {
		font-size: 17px;
	}
}

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;
    /* position: sticky; */
    top: 0;
    z-index: 500;
    background-color: var(--light-bg-color);
}

header span {
    color: var(--accent-color);
}

nav {
    display: flex;
    align-items: center;
}

.small-logo {
    display: none;
    padding-left: 40px;
    padding-bottom: 40px;
}

nav ul {
    display: flex;
}

nav ul li {
    list-style-type: none;
    margin-left: 10px;
}
nav ul li a {
    text-decoration: none;
    color: white;
    padding: 5px 12px;
    border-radius: 5px;
    border: solid 1px transparent;
    transition: all 0.25s ease;
}

nav ul li a:hover {
    color: var(--accent-color);
}

.color-wheel-container {
    display: flex;
    align-items: center;
    justify-items: flex-end;
    margin-left: 10px;
    padding: 5px 12px;
}

.color-wheel {
    color: var(--accent-color);
    -webkit-transition: transform 0.4s;
    -moz-transition: transform 0.4s;
    -ms-transition: transform 0.4s;
    -o-transition: transform 0.4s;
    transition: transform 0.4s;
}

.color-wheel:hover {
    cursor: pointer;
    transform: scale(1.3);
    -webkit-transition: transform 0.4s;
    -moz-transition: transform 0.4s;
    -ms-transition: transform 0.4s;
    -o-transition: transform 0.4s;
    transition: transform 0.4s;
}

.menu-btn {
    font-size: 1.8em;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    display: none;
    z-index: 999;
}
header h1:hover {
    cursor: pointer;
}

.menu-btn:hover {
    color: var(--accent-color);
}

@media (max-width: 955px) {
    .menu-btn {
        display: flex;
    }

    nav ul {
        position: fixed;
        height: 100vh;
        width: 100%;
        left: -100%;
        top: 0;
        background: var(--light-bg-color);
        z-index: 100;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding-bottom: 60px;
        transition: all 0.3s ease;
    }

    nav ul.active {
        left: 0;
    }
    ul.active .small-logo {
        display: block;
    }
    ul.active + .menu-btn {
        position: fixed;
        right: 30px;
        top: 20px;
        transform: rotate(180deg);
    }

    nav ul li a {
        display: inline-block;
        margin: 10px 0px;
        font-size: 22px;
    }
}

@media (max-width: 550px) {
    header {
        padding: 10px 25px;
    }
    ul.active + .menu-btn {
        right: 20px;
        top: 20px;
    }
    nav ul li a {
        font-size: 20px;
    }
}

@media (max-width: 500px) {
    header {
        padding: 10px 20px;
    }
    header h1 {
        font-size: 30px;
    }
    .menu-btn {
        font-size: 1.5em;
    }
}

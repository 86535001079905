.scroll-to-top {
	display: inline-block;
	font-size: 1.9em;
	position: fixed;
	right: 0.8em;
	bottom: 0.8em;
	opacity: 1;
	transition: opacity 0.3s ease;
}

.scroll-to-top.hide {
	pointer-events: none;
	opacity: 0;
	/* right: -100%; */
}

.scroll-to-top:hover {
	cursor: pointer;
	color: var(--accent-color);
}

@media (max-width: 550px) {
	.scroll-to-top {
		right: 0.5em;
		bottom: 0.7em;
	}
}

@media (max-width: 500px) {
	.scroll-to-top {
		right: 0.4em;
		bottom: 0.6em;
	}
}

.project-container {
	display: grid;
	grid-template-columns: 1fr;
	width: 100%;
	gap: 30px;
}

.project {
	display: flex;
	flex-direction: column;
	background-color: var(--light-bg-color);
	border-radius: 5px;
	padding: 20px;
}

.project a {
	color: var(--accent-color);
}
.project-info {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;
}

.project img {
	width: 100%;
	max-height: 315px;
	border-radius: 5px;
	border: solid 1px rgb(95, 93, 93);
	margin-bottom: 10px;
	max-width: 450px;
	margin-left: auto;
	margin-right: auto;
}
.project-title {
	text-align: center;
	font-size: 28px;
}

.project-heading {
	font-size: 25px;
}
.project-heading::before {
	/* content: "\2192"; */
	content: "#";
	margin-right: 5px;
	color: var(--accent-color);
}
.project-heading::after {
	content: ":";
	color: var(--accent-color);
	margin-left: 3px;
}

.tags-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin-top: 10px;
	margin-bottom: 20px;
}

.tag {
	font-family: "Roboto Mono", monospace;
	font-size: 15px;
	margin-left: 10px;
}

.tag::before {
	content: "\2022";
	color: var(--accent-color);
	font-size: 18px;
	font-weight: bold;
	display: inline-block;
	width: 1em;
	margin-left: -0.8em;
}

.project-buttons {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 20px;
}
.project-buttons a {
	color: var(--accent-color);
}

.link-button {
	margin: 0;
}

@media (max-width: 900px) {
	.project-container {
		grid-template-columns: 1fr;
		width: 100%;
	}
	.project img {
		max-width: 450px;
		margin-left: auto;
		margin-right: auto;
	}
}

@media (max-width: 550px) {
	.project-title {
		font-size: 25px;
	}
}

@media (max-width: 400px) {
	.project-buttons .link-button {
		padding: 12px 25px;
	}
}
